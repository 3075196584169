import { setByPath } from '@hangar31/rc-utils';
import { deepmerge } from 'deepmerge-ts';
import { GraphQLClient } from 'graphql-request';
import { GetServerSidePropsContext, Redirect } from 'next';
import Cookies from 'universal-cookie';

import { getSdk, Sdk } from '@Usician/sdk';

const redirectAfterAuth = ({
  authRole,
  membershipStatus,
}: UnArray<WithAuthUser['localInfo']>) => {
  if (authRole === 'ADMIN') {
    return '/admin/lookup';
  }
  if (authRole === 'MEMBER') {
    // path depending on role
    const paths = {
      // go to dashboard
      ACTIVE: '/member',
      // go to  ???
      DECEASED: '/member',
      // go to expelled page
      EXPELLED: '/member',
      // go to dashboard
      MEMBER: '/member',
      // go to the application
      NONE: '/application',
      // to the page to get reinstated
      RESIGNED: '/member',
      // got to dashboard
      SUSPENDED: '/member',
    };
    return paths[membershipStatus || 'NONE'];
  }
  if (authRole === 'NONE') {
    return '/application';
  }
  if (authRole === 'MIGRATION') {
    return '/account-update';
  }
  return '/';
};

declare type PrivateRoute =
  | 'PUBLIC' //anyone
  | 'REDIRECT' // redirect if logged in to admin
  | 'ADMIN' //only admins
  | 'MEMBER' // only members
  | 'SUPER'; //only super admins

type WithAuthUser = Exclude<
  Await<ReturnType<Sdk['me']>>['user'],
  null | undefined
>;

type WithAuthSettings = Exclude<
  Await<ReturnType<Sdk['getSettings']>>['settings'],
  null | undefined
>;
export type WithAuthPages = Exclude<
  Await<ReturnType<Sdk['me']>>['resourcePages'],
  null | undefined
>;

export type WithAuthCleanUser = Omit<WithAuthUser, 'localInfo'> & {
  localInfo: UnArray<WithAuthUser['localInfo']>;
};

type WithAuth = <R>(
  getPageProps: (
    context: GetServerSidePropsContext & {
      client: Sdk;
      resourcePages?: WithAuthPages;
      user?: WithAuthCleanUser;
      settings?: Settings;
    }
  ) => Promise<
    | {
        props: R & {
          resourcePages?: DeepPartial<WithAuthPages>;
          settings?: DeepPartial<Settings>;
          user?: DeepPartial<WithAuthCleanUser>;
        };
        redirect?: never;
        notFound?: never;
      }
    | { redirect: Redirect; props?: never; notFound?: never }
    | { notFound: true; props?: never; redirect?: never }
  >,
  privateRoute: PrivateRoute,
  getSettings?: string[]
) => (context: GetServerSidePropsContext) => Promise<
  | {
      props: R & {
        resourcePages: Exclude<WithAuthPages, undefined>;
        settings: Exclude<Settings, undefined>;
        user: Exclude<WithAuthCleanUser, undefined>;
      };
      redirect?: never;
      notFound?: never;
    }
  | {
      redirect: Redirect;
      props?: never;
      notFound?: never;
    }
  | {
      notFound: true;
      props?: never;
      redirect?: never;
    }
>;

const withAuth: WithAuth = (
  getPageProps,
  privateRoute = 'PUBLIC',
  getSettings = []
) => {
  return async ctx => {
    const { cookie, host } = ctx.req.headers;
    const cookies = new Cookies(cookie);
    const token = cookies.get('usician-token') as string;
    const client = new GraphQLClient(
      process.env.NEXT_PUBLIC_GRAPHQL_URI as string,
      {
        mode: 'cors',
      }
    );
    if (token) client.setHeader('authorization', token);
    client.setHeader('domain', host || '');

    const ContextWithClient = { ...ctx, client: getSdk(client) };
    const $in = ['appearance', 'information', ...getSettings];
    let settings: WithAuthSettings | undefined | null;
    let user: WithAuthUser | undefined | null;
    let resourcePages: WithAuthPages | undefined | null;
    let cleanUser: WithAuthCleanUser;

    // if there is no auth
    if (!token) {
      // if it is a private route
      if (privateRoute !== 'PUBLIC' && privateRoute !== 'REDIRECT')
        return {
          redirect: {
            destination: '/',
            permanent: false,
          },
        };
    }
    // if we are logged in get the user
    if (token) {
      ({ resourcePages, settings, user } = await ContextWithClient.client.me({
        in: $in,
      }));
    } else if (privateRoute === 'PUBLIC' || privateRoute === 'REDIRECT') {
      ({ settings } = await ContextWithClient.client.getSettings({ in: $in }));
    }

    // if this is a private route and there is no user, go to the home page
    if (
      privateRoute !== 'PUBLIC' &&
      privateRoute !== 'REDIRECT' &&
      (!user || !user?.localInfo?.[0])
    ) {
      return {
        redirect: {
          destination: '/',
          permanent: false,
        },
      };
    }

    if (
      (privateRoute === 'REDIRECT' && user) ||
      (privateRoute === 'ADMIN' && user?.localInfo?.[0]?.authRole !== 'ADMIN')
    ) {
      // if this is a public route and you are logged in, redirect to the admin
      return {
        redirect: {
          destination: redirectAfterAuth(user?.localInfo?.[0] || {}),
          permanent: false,
        },
      };
    }
    const mappedSettings = settings?.reduce((acc, { name, value }) => {
      const names = [
        'hall',
        'information',
        'appearance',
        'application',
        'payments',
      ];
      if (names.includes(name)) setByPath(acc, name, value);
      return acc;
    }, {} as Settings);

    cleanUser = {
      ...(user as WithAuthUser),
      localInfo: {
        ...user?.localInfo?.[0],
      },
    };
    const pageProps = await getPageProps({
      ...ContextWithClient,
      resourcePages: resourcePages || ([] as WithAuthPages),
      settings: mappedSettings || ({} as Settings),
      user: cleanUser || ({} as WithAuthCleanUser),
    });

    if (pageProps.redirect !== undefined || pageProps.notFound !== undefined) {
      return pageProps;
    }

    if (user && pageProps.props?.user) {
      cleanUser = deepmerge(
        cleanUser,
        (pageProps.props.user as WithAuthCleanUser) || ({} as WithAuthCleanUser)
      );
    }
    const props = {
      ...pageProps,
      props: {
        ...pageProps.props,
        host,
        resourcePages: [
          ...(resourcePages || []),
          ...(pageProps.props?.resourcePages || []),
        ] as WithAuthPages,
        settings: {
          ...mappedSettings,
          ...(pageProps.props?.settings || {}),
        } as Settings,
        url: ctx.req.url,
        user: cleanUser as WithAuthCleanUser,
      },
    };
    return props;
  };
};

export default withAuth;
