import { ReactElement } from 'react';

import { GraphQLClient } from 'graphql-request';
import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import Cookies from 'universal-cookie';

import { GRAPHQL_URI } from '@Usician/constants';
// eslint-disable-next-line import/no-unresolved, import/extensions
import { getSdk } from '@Usician/sdk';

type WithClient = <
  R extends { [key: string]: any; token?: string; guestToken?: string },
  P extends ReactElement
>(
  PageComponent: (
    props: R & {
      client: Sdk;
    }
  ) => P
) => (pageProps: R) => P;

const withClient: WithClient = PageComponent => {
  return pageProps => {
    const { token = new Cookies().get('usician-token') } = pageProps;
    const { host, url } = pageProps;
    const client = new GraphQLClient(GRAPHQL_URI, {
      mode: 'cors',
    });

    if (token) client.setHeader('authorization', token);
    client.setHeader('domain', host || '');
    if (url) client.setHeader('usician-url', url);

    return PageComponent({ ...pageProps, client: getSdk(client) });
  };
};

export default withClient;

type GetClient = <R extends GetServerSidePropsResult<any>>(
  getPageProps: (
    context: GetServerSidePropsContext & { client: Sdk }
  ) => Promise<R>
) => (context: GetServerSidePropsContext) => Promise<R>;

// & { props?: { guestToken: string; token: string } }
export const getClient: GetClient = getPageProps => async ctx => {
  const { cookie, host } = ctx.req.headers;
  const cookies = new Cookies(cookie);
  const token = cookies.get('usician-token');
  const client = new GraphQLClient(GRAPHQL_URI, {
    mode: 'cors',
  });

  if (token) client.setHeader('authorization', token);
  client.setHeader('domain', host || '');

  const ContextWithClient = { ...ctx, client: getSdk(client) };

  const result = await getPageProps(ContextWithClient);
  if (!('props' in result)) return result;
  const pageProps = result.props || {};
  const props = {
    ...result,
    props: {
      ...pageProps,
      token: token || '',
      url: ctx.req.url,
    },
  };

  return props;
};
