import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';

 const MeDocument = gql`
    query me($in: [String]) {
  user: me {
    id
    avatar
    firstName
    lastName
    email
    phone
    directoryInfo
    applicationInfo
    localInfo(where: {localId: {equals: "LOCAL_ID"}}) {
      authRole
      membershipType
      membershipStatus
      goodStandingThru
      publicDirectory
    }
  }
  settings: findManySetting(
    where: {localId: {equals: "LOCAL_ID"}, name: {in: $in}}
  ) {
    name
    value
  }
  resourcePages: findManyResourcePage(
    where: {localId: {equals: "LOCAL_ID"}}
    orderBy: {order: asc}
  ) {
    id
    name
    slug
    order
  }
}
    `;
 const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  data: login(email: $email, password: $password) {
    token
  }
}
    `;
 const SignupDocument = gql`
    mutation signup($email: String!, $password: String!) {
  data: signup(email: $email, password: $password) {
    token
  }
}
    `;
 const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  data: forgotPassword(email: $email) {
    message
  }
}
    `;
 const ResetPasswordDocument = gql`
    mutation resetPassword($newPassword: String!, $resetPasswordToken: String!) {
  data: resetPassword(
    newPassword: $newPassword
    resetPasswordToken: $resetPasswordToken
  ) {
    token
  }
}
    `;
 const UpdatePasswordDocument = gql`
    mutation updatePassword($currentPassword: String!, $password: String!) {
  complete: updatePassword(currentPassword: $currentPassword, password: $password)
}
    `;
 const UpdateUserEmailDocument = gql`
    mutation updateUserEmail($currentEmail: String!, $email: String!) {
  complete: updateEmail(currentEmail: $currentEmail, email: $email)
}
    `;
 const UpdateUserDocument = gql`
    mutation updateUser($data: UserUpdateInput!) {
  data: updateUser(data: $data)
}
    `;
 const GetAutomationsDocument = gql`
    query getAutomations {
  automations: findManyAutomation(where: {local: {id: {equals: "LOCAL_ID"}}}) {
    id
    date
    action
    statuses
    goodStandingThru
    settings
    order
  }
}
    `;
 const CreateAutomationDocument = gql`
    mutation createAutomation($data: AutomationCreateInput!) {
  automation: createOneAutomation(data: $data) {
    id
    date
    action
    statuses
    goodStandingThru
    settings
    order
  }
}
    `;
 const UpdateAutomationDocument = gql`
    mutation updateAutomation($data: AutomationUpdateInput!, $id: String) {
  automation: updateOneAutomation(data: $data, where: {id: $id}) {
    id
    date
    action
    statuses
    goodStandingThru
    settings
    order
  }
}
    `;
 const DeleteAutomationDocument = gql`
    mutation deleteAutomation($id: String) {
  deleteOneAutomation(where: {id: $id}) {
    id
  }
}
    `;
 const GetMemberDirectoryDocument = gql`
    query getMemberDirectory {
  instruments: findManyInstrument {
    name
    code
  }
  musicStyles: findManyMusicStyle {
    name
    code
  }
  members: findManyUser(
    where: {localInfo: {some: {localId: {equals: "LOCAL_ID"}, AND: {privateDirectory: {equals: true}}}}}
  ) {
    id
    avatar
    firstName
    lastName
    email
    phone
    directoryInfo
    userInstruments {
      instrument {
        code
        name
      }
      play
      teach
      createdAt
    }
    musicStyles {
      code
      name
      createdAt
    }
    localInfo {
      membershipStatus
      membershipType
      authRole
    }
  }
}
    `;
 const GetDirecoryInfoDocument = gql`
    query getDirecoryInfo($userId: String!) {
  instruments: findManyInstrument(orderBy: {name: asc}) {
    name
    code
  }
  musicStyles: findManyMusicStyle {
    name
    code
  }
  member: findUniqueUser(where: {id: $userId}) {
    id
    firstName
    lastName
    directoryInfo
    localInfo(where: {localId: {equals: "LOCAL_ID"}}) {
      publicDirectory
    }
    userInstruments {
      code
      play
      teach
      createdAt
    }
    musicStyles {
      code
      name
      createdAt
    }
  }
}
    `;
 const GetAdminLookupDocument = gql`
    query getAdminLookup {
  members: findManyUser(
    where: {localInfo: {some: {localId: {equals: "LOCAL_ID"}, authRole: {not: {equals: NONE}}}}}
  ) {
    id
    avatar
    firstName
    lastName
    email
    phone
    directoryInfo
    applicationInfo
    localInfo(where: {localId: {equals: "LOCAL_ID"}}) {
      membershipStatus
      membershipType
      createdAt
      authRole
      privateDirectory
      publicDirectory
      billingCycleQuarters
      goodStandingThru
      lockActiveStatus
      disableAutomation
      joinDate
    }
  }
}
    `;
 const GetInstrumentsDocument = gql`
    query getInstruments {
  instruments: findManyInstrument(orderBy: {name: asc}) {
    name
    code
  }
}
    `;
 const GetEmailsDocument = gql`
    query getEmails {
  emails: findManyEmail(where: {local: {id: {equals: "LOCAL_ID"}}}) {
    id
    name
    subject
    content
  }
}
    `;
 const CreateEmailDocument = gql`
    mutation createEmail($data: EmailCreateInput!) {
  email: createOneEmail(data: $data) {
    id
    name
    subject
    content
  }
}
    `;
 const UpdateEmailDocument = gql`
    mutation updateEmail($data: EmailUpdateInput!, $id: String) {
  email: updateOneEmail(data: $data, where: {id: $id}) {
    id
    name
    subject
    content
  }
}
    `;
 const DeleteEmailDocument = gql`
    mutation deleteEmail($id: String) {
  deleteOneEmail(where: {id: $id}) {
    id
  }
}
    `;
 const ContactFormDocument = gql`
    mutation contactForm($data: ContactFormInput!) {
  data: contactForm(data: $data) {
    message
  }
}
    `;
 const UpdateSettingsDocument = gql`
    mutation updateSettings($data: SettingUpdateInput!, $name: String!) {
  data: updateOneSetting(
    data: $data
    where: {name_localId: {name: $name, localId: "LOCAL_ID"}}
  ) {
    value
  }
}
    `;
 const RequestFormDocument = gql`
    mutation requestForm($message: String!) {
  data: requestForm(message: $message) {
    message
  }
}
    `;
 const GetCurrentMeetingsDocument = gql`
    query getCurrentMeetings($date: DateTime, $limit: Int) {
  meetings: findManyMeeting(
    where: {date: {gte: $date}, localId: {equals: "LOCAL_ID"}}
    orderBy: {date: asc}
    take: $limit
  ) {
    id
    address
    date
    description
    link
    rsvp
    time
    title
  }
}
    `;
 const GetAllMeetingsDocument = gql`
    query getAllMeetings {
  meetings: findManyMeeting(
    where: {localId: {equals: "LOCAL_ID"}}
    orderBy: {date: asc}
  ) {
    id
    address
    date
    description
    link
    rsvp
    time
    title
  }
}
    `;
 const CreateMeetingDocument = gql`
    mutation createMeeting($data: MeetingCreateInput!) {
  meeting: createOneMeeting(data: $data) {
    id
    address
    date
    description
    link
    rsvp
    time
    title
  }
}
    `;
 const UpdateMeetingDocument = gql`
    mutation updateMeeting($data: MeetingUpdateInput!, $id: String) {
  meeting: updateOneMeeting(data: $data, where: {id: $id}) {
    id
    address
    date
    description
    link
    rsvp
    time
    title
  }
}
    `;
 const DeleteMeetingDocument = gql`
    mutation deleteMeeting($id: String) {
  deleteOneMeeting(where: {id: $id}) {
    id
  }
}
    `;
 const GetApplicationInfoDocument = gql`
    query getApplicationInfo($userId: String) {
  member: findUniqueUser(where: {id: $userId}) {
    id
    applicationInfo
    avatar
    directoryInfo
    email
    firstName
    lastName
    middleName
    phone
    localInfo(where: {localId: {equals: "LOCAL_ID"}}) {
      authRole
      membershipType
      membershipStatus
      goodStandingThru
    }
  }
}
    `;
 const InvoicesDocument = gql`
    query invoices($userId: String!) {
  invoices(userId: $userId) {
    amount
    created
    date
    status
    title
    url
  }
}
    `;
 const PaymentInfoDocument = gql`
    query paymentInfo($userId: String!) {
  member: findUniqueUser(where: {id: $userId}) {
    id
    firstName
    lastName
    localInfo {
      billingCycleQuarters
      autoPay
      autoRenew
      membershipStatus
      membershipType
    }
  }
  card: card(userId: $userId)
}
    `;
 const MemberSettingsDocument = gql`
    query memberSettings($userId: String!) {
  member: findUniqueUser(where: {id: $userId}) {
    id
    firstName
    lastName
    localInfo {
      authRole
      membershipType
      lockActiveStatus
      disableAutomation
      goodStandingThru
    }
  }
}
    `;
 const GetMemberDocument = gql`
    query getMember($userId: String) {
  user: findUniqueUser(where: {id: $userId}) {
    id
    firstName
    lastName
    email
    phone
    directoryInfo
    localInfo(where: {localId: {equals: "LOCAL_ID"}}) {
      authRole
      membershipType
      membershipStatus
      goodStandingThru
    }
  }
}
    `;
 const UpdateMemberDocument = gql`
    mutation updateMember($data: UserUpdateInput!, $userId: String!) {
  data: updateOneUser(data: $data, where: {id: $userId}) {
    firstName
  }
}
    `;
 const AddMemberHistoryDocument = gql`
    mutation addMemberHistory($data: MemberHistoryCreateInput!) {
  history: createOneMemberHistory(data: $data) {
    id
    type
    status
    date
    localName
    localNumber
    note
  }
}
    `;
 const DeleteMemberDocument = gql`
    mutation deleteMember($id: String!) {
  deleteOneLocalInfo(where: {localId_userId: {localId: "LOCAL_ID", userId: $id}}) {
    userId
  }
}
    `;
 const FullActivityLogDocument = gql`
    query fullActivityLog {
  log: findManyChangeLog(
    where: {user: {localInfo: {some: {localId: {equals: "LOCAL_ID"}}}}}
    orderBy: {createdAt: desc}
  ) {
    id
    before
    after
    note
    createdAt
    user {
      id
      firstName
      lastName
      localInfo {
        localId
      }
    }
    changedBy {
      id
      firstName
      lastName
    }
  }
}
    `;
 const GetUserLogDocument = gql`
    query getUserLog($userId: String) {
  member: findUniqueUser(where: {id: $userId}) {
    id
    firstName
    lastName
    history {
      id
      type
      status
      date
      localName
      localNumber
      note
    }
  }
  log: findManyChangeLog(
    where: {userId: {equals: $userId}}
    orderBy: {createdAt: desc}
  ) {
    id
    before
    after
    note
    createdAt
    user {
      id
      firstName
      lastName
      localInfo {
        localId
      }
    }
    changedBy {
      id
      firstName
      lastName
    }
  }
}
    `;
 const GetCouponsDocument = gql`
    query getCoupons {
  coupons: findManyCoupon(
    where: {local: {domain: {equals: "LOCAL_DOMAIN"}}, valid: {equals: true}}
  ) {
    code
    amountOff
    percentOff
  }
}
    `;
 const GetAllCouponsDocument = gql`
    query getAllCoupons {
  coupons: findManyCoupon(where: {local: {id: {equals: "LOCAL_ID"}}}) {
    id
    code
    amountOff
    percentOff
    valid
  }
}
    `;
 const ApplicationDocument = gql`
    mutation application($data: UserUpdateInput!) {
  data: application(data: $data)
}
    `;
 const FirstBillDocument = gql`
    mutation firstBill($data: FirstBillInput!) {
  data: firstBill(data: $data) {
    message
  }
}
    `;
 const NewCardDocument = gql`
    mutation newCard($userId: String!, $card: CardInput!) {
  last4: newCard(userId: $userId, card: $card)
}
    `;
 const DeleteCardDocument = gql`
    mutation deleteCard($userId: String!) {
  success: deleteCard(userId: $userId)
}
    `;
 const CreateCouponDocument = gql`
    mutation createCoupon($data: CouponCreateInput!) {
  coupon: createOneCoupon(data: $data) {
    id
    code
    amountOff
    valid
  }
}
    `;
 const UpdateCouponDocument = gql`
    mutation updateCoupon($data: CouponUpdateInput!, $id: String) {
  coupon: updateOneCoupon(data: $data, where: {id: $id}) {
    id
    code
    amountOff
    valid
  }
}
    `;
 const DeleteCouponDocument = gql`
    mutation deleteCoupon($id: String) {
  deleteOneCoupon(where: {id: $id}) {
    id
  }
}
    `;
 const GetMembersBreakdownDocument = gql`
    query getMembersBreakdown {
  members: findManyLocalInfo(
    where: {localId: {equals: "LOCAL_ID"}, authRole: {not: {equals: NONE}}}
  ) {
    membershipStatus
    membershipType
  }
}
    `;
 const GetMembersReportDocument = gql`
    query getMembersReport($status: [MembershipStatus], $type: [String]) {
  members: findManyUser(
    where: {localInfo: {some: {localId: {equals: "LOCAL_ID"}, membershipStatus: {in: $status}, membershipType: {in: $type}}}}
  ) {
    id
    firstName
    lastName
    email
    phone
    applicationInfo
    localInfo {
      membershipStatus
      membershipType
      authRole
    }
  }
}
    `;
 const GetNewMembersDocument = gql`
    query getNewMembers($gte: DateTime, $lte: DateTime) {
  members: findManyUser(
    where: {localInfo: {some: {localId: {equals: "LOCAL_ID"}, joinDate: {gte: $gte, lte: $lte}}}}
  ) {
    id
    firstName
    lastName
    email
    phone
    applicationInfo
    localInfo {
      membershipStatus
      membershipType
      authRole
    }
  }
}
    `;
 const GetDirectoryReportDocument = gql`
    query getDirectoryReport($instruments: [String]) {
  members: findManyInstrument(
    where: {userInstruments: {some: {users: {localInfo: {some: {localId: {equals: "LOCAL_ID"}, membershipStatus: {equals: ACTIVE}}}}}}, code: {in: $instruments}}
  ) {
    name
    userInstruments {
      play
      teach
      users {
        id
        firstName
        lastName
        email
        phone
        directoryInfo
        userInstruments {
          instrument {
            name
          }
        }
        localInfo {
          membershipStatus
          membershipType
          authRole
        }
        musicStyles {
          name
        }
      }
    }
  }
}
    `;
 const GetDirectoryPdfDocument = gql`
    query getDirectoryPDF($instruments: [String]) {
  instruments: findManyInstrument(
    where: {userInstruments: {some: {users: {localInfo: {some: {localId: {equals: "LOCAL_ID"}, membershipStatus: {equals: ACTIVE}}}}}}, code: {in: $instruments}}
    orderBy: {name: asc}
  ) {
    name
    userInstruments {
      users {
        id
        firstName
        lastName
        phone
        email
      }
    }
  }
}
    `;
 const GetContactPdfDocument = gql`
    query getContactPDF {
  members: findManyUser(
    where: {localInfo: {some: {localId: {equals: "LOCAL_ID"}, membershipStatus: {equals: ACTIVE}}}}
  ) {
    id
    firstName
    lastName
    email
    phone
    applicationInfo
  }
}
    `;
 const CreateResourcePageDocument = gql`
    mutation createResourcePage($data: ResourcePageCreateInput!) {
  page: createOneResourcePage(data: $data) {
    id
    name
    slug
  }
}
    `;
 const GetResourcePageDocument = gql`
    query getResourcePage($slug: String!) {
  page: findUniqueResourcePage(
    where: {localId_slug: {localId: "LOCAL_ID", slug: $slug}}
  ) {
    id
    name
    slug
    order
    categories(orderBy: {order: asc}) {
      id
      name
      order
      slug
      resources(orderBy: {order: asc}) {
        id
        image
        title
        description
        link
        order
        type
      }
    }
  }
}
    `;
 const DeleteResourcePageDocument = gql`
    mutation deleteResourcePage($id: String) {
  deleteOneResourcePage(where: {id: $id}) {
    id
  }
}
    `;
 const UpdateResourcePageDocument = gql`
    mutation updateResourcePage($data: ResourcePageUpdateInput!, $id: String) {
  page: updateOneResourcePage(data: $data, where: {id: $id}) {
    id
    name
    slug
  }
}
    `;
 const CreateResourceCategoryDocument = gql`
    mutation createResourceCategory($data: ResourceCategoryCreateInput!) {
  category: createOneResourceCategory(data: $data) {
    id
    name
    slug
    order
  }
}
    `;
 const UpdateResourceCategoryDocument = gql`
    mutation updateResourceCategory($data: ResourceCategoryUpdateInput!, $id: String) {
  category: updateOneResourceCategory(data: $data, where: {id: $id}) {
    id
    name
    slug
    order
  }
}
    `;
 const DeleteResourceCategoryDocument = gql`
    mutation deleteResourceCategory($id: String) {
  deleteOneResourceCategory(where: {id: $id}) {
    id
  }
}
    `;
 const CreateResourceDocument = gql`
    mutation createResource($data: ResourceCreateInput!) {
  resource: createOneResource(data: $data) {
    id
    image
    title
    description
    link
    order
    type
  }
}
    `;
 const UpdateResourceDocument = gql`
    mutation updateResource($data: ResourceUpdateInput!, $id: String) {
  resource: updateOneResource(data: $data, where: {id: $id}) {
    id
    image
    title
    description
    link
    order
    type
  }
}
    `;
 const DeleteResourceDocument = gql`
    mutation deleteResource($id: String) {
  deleteOneResource(where: {id: $id}) {
    id
  }
}
    `;
 const GetSettingsDocument = gql`
    query getSettings($in: [String]) {
  settings: findManySetting(
    where: {local: {domain: {equals: "LOCAL_DOMAIN"}}, name: {in: $in}}
  ) {
    name
    value
  }
}
    `;
 const ImportUsersDocument = gql`
    mutation importUsers($users: [UserCreateInput]) {
  imported: ImportUsers(users: $users)
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    me(variables?: MeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<MeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MeQuery>(MeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'me');
    },
    login(variables: LoginMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<LoginMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<LoginMutation>(LoginDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'login');
    },
    signup(variables: SignupMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SignupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SignupMutation>(SignupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'signup');
    },
    forgotPassword(variables: ForgotPasswordMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ForgotPasswordMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ForgotPasswordMutation>(ForgotPasswordDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'forgotPassword');
    },
    resetPassword(variables: ResetPasswordMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ResetPasswordMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ResetPasswordMutation>(ResetPasswordDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'resetPassword');
    },
    updatePassword(variables: UpdatePasswordMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdatePasswordMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdatePasswordMutation>(UpdatePasswordDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updatePassword');
    },
    updateUserEmail(variables: UpdateUserEmailMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateUserEmailMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateUserEmailMutation>(UpdateUserEmailDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateUserEmail');
    },
    updateUser(variables: UpdateUserMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateUserMutation>(UpdateUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateUser');
    },
    getAutomations(variables?: GetAutomationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetAutomationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAutomationsQuery>(GetAutomationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAutomations');
    },
    createAutomation(variables: CreateAutomationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateAutomationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateAutomationMutation>(CreateAutomationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createAutomation');
    },
    updateAutomation(variables: UpdateAutomationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateAutomationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateAutomationMutation>(UpdateAutomationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateAutomation');
    },
    deleteAutomation(variables?: DeleteAutomationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteAutomationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteAutomationMutation>(DeleteAutomationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteAutomation');
    },
    getMemberDirectory(variables?: GetMemberDirectoryQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetMemberDirectoryQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetMemberDirectoryQuery>(GetMemberDirectoryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getMemberDirectory');
    },
    getDirecoryInfo(variables: GetDirecoryInfoQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetDirecoryInfoQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetDirecoryInfoQuery>(GetDirecoryInfoDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getDirecoryInfo');
    },
    getAdminLookup(variables?: GetAdminLookupQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetAdminLookupQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAdminLookupQuery>(GetAdminLookupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAdminLookup');
    },
    getInstruments(variables?: GetInstrumentsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetInstrumentsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetInstrumentsQuery>(GetInstrumentsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getInstruments');
    },
    getEmails(variables?: GetEmailsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetEmailsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetEmailsQuery>(GetEmailsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getEmails');
    },
    createEmail(variables: CreateEmailMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateEmailMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateEmailMutation>(CreateEmailDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createEmail');
    },
    updateEmail(variables: UpdateEmailMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateEmailMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateEmailMutation>(UpdateEmailDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateEmail');
    },
    deleteEmail(variables?: DeleteEmailMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteEmailMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteEmailMutation>(DeleteEmailDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteEmail');
    },
    contactForm(variables: ContactFormMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ContactFormMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ContactFormMutation>(ContactFormDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'contactForm');
    },
    updateSettings(variables: UpdateSettingsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateSettingsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateSettingsMutation>(UpdateSettingsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateSettings');
    },
    requestForm(variables: RequestFormMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RequestFormMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RequestFormMutation>(RequestFormDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'requestForm');
    },
    getCurrentMeetings(variables?: GetCurrentMeetingsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetCurrentMeetingsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCurrentMeetingsQuery>(GetCurrentMeetingsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCurrentMeetings');
    },
    getAllMeetings(variables?: GetAllMeetingsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetAllMeetingsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAllMeetingsQuery>(GetAllMeetingsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAllMeetings');
    },
    createMeeting(variables: CreateMeetingMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateMeetingMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateMeetingMutation>(CreateMeetingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createMeeting');
    },
    updateMeeting(variables: UpdateMeetingMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateMeetingMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateMeetingMutation>(UpdateMeetingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateMeeting');
    },
    deleteMeeting(variables?: DeleteMeetingMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteMeetingMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteMeetingMutation>(DeleteMeetingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteMeeting');
    },
    getApplicationInfo(variables?: GetApplicationInfoQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetApplicationInfoQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetApplicationInfoQuery>(GetApplicationInfoDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getApplicationInfo');
    },
    invoices(variables: InvoicesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<InvoicesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<InvoicesQuery>(InvoicesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'invoices');
    },
    paymentInfo(variables: PaymentInfoQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PaymentInfoQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PaymentInfoQuery>(PaymentInfoDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'paymentInfo');
    },
    memberSettings(variables: MemberSettingsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<MemberSettingsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MemberSettingsQuery>(MemberSettingsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'memberSettings');
    },
    getMember(variables?: GetMemberQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetMemberQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetMemberQuery>(GetMemberDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getMember');
    },
    updateMember(variables: UpdateMemberMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateMemberMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateMemberMutation>(UpdateMemberDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateMember');
    },
    addMemberHistory(variables: AddMemberHistoryMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddMemberHistoryMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddMemberHistoryMutation>(AddMemberHistoryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'addMemberHistory');
    },
    deleteMember(variables: DeleteMemberMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteMemberMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteMemberMutation>(DeleteMemberDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteMember');
    },
    fullActivityLog(variables?: FullActivityLogQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FullActivityLogQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FullActivityLogQuery>(FullActivityLogDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'fullActivityLog');
    },
    getUserLog(variables?: GetUserLogQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetUserLogQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetUserLogQuery>(GetUserLogDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getUserLog');
    },
    getCoupons(variables?: GetCouponsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetCouponsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCouponsQuery>(GetCouponsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCoupons');
    },
    getAllCoupons(variables?: GetAllCouponsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetAllCouponsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAllCouponsQuery>(GetAllCouponsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAllCoupons');
    },
    application(variables: ApplicationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ApplicationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ApplicationMutation>(ApplicationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'application');
    },
    firstBill(variables: FirstBillMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FirstBillMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<FirstBillMutation>(FirstBillDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'firstBill');
    },
    newCard(variables: NewCardMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<NewCardMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<NewCardMutation>(NewCardDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'newCard');
    },
    deleteCard(variables: DeleteCardMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteCardMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteCardMutation>(DeleteCardDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteCard');
    },
    createCoupon(variables: CreateCouponMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateCouponMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateCouponMutation>(CreateCouponDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createCoupon');
    },
    updateCoupon(variables: UpdateCouponMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateCouponMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateCouponMutation>(UpdateCouponDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateCoupon');
    },
    deleteCoupon(variables?: DeleteCouponMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteCouponMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteCouponMutation>(DeleteCouponDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteCoupon');
    },
    getMembersBreakdown(variables?: GetMembersBreakdownQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetMembersBreakdownQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetMembersBreakdownQuery>(GetMembersBreakdownDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getMembersBreakdown');
    },
    getMembersReport(variables?: GetMembersReportQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetMembersReportQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetMembersReportQuery>(GetMembersReportDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getMembersReport');
    },
    getNewMembers(variables?: GetNewMembersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetNewMembersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetNewMembersQuery>(GetNewMembersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getNewMembers');
    },
    getDirectoryReport(variables?: GetDirectoryReportQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetDirectoryReportQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetDirectoryReportQuery>(GetDirectoryReportDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getDirectoryReport');
    },
    getDirectoryPDF(variables?: GetDirectoryPdfQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetDirectoryPdfQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetDirectoryPdfQuery>(GetDirectoryPdfDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getDirectoryPDF');
    },
    getContactPDF(variables?: GetContactPdfQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetContactPdfQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetContactPdfQuery>(GetContactPdfDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getContactPDF');
    },
    createResourcePage(variables: CreateResourcePageMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateResourcePageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateResourcePageMutation>(CreateResourcePageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createResourcePage');
    },
    getResourcePage(variables: GetResourcePageQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetResourcePageQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetResourcePageQuery>(GetResourcePageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getResourcePage');
    },
    deleteResourcePage(variables?: DeleteResourcePageMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteResourcePageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteResourcePageMutation>(DeleteResourcePageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteResourcePage');
    },
    updateResourcePage(variables: UpdateResourcePageMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateResourcePageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateResourcePageMutation>(UpdateResourcePageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateResourcePage');
    },
    createResourceCategory(variables: CreateResourceCategoryMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateResourceCategoryMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateResourceCategoryMutation>(CreateResourceCategoryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createResourceCategory');
    },
    updateResourceCategory(variables: UpdateResourceCategoryMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateResourceCategoryMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateResourceCategoryMutation>(UpdateResourceCategoryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateResourceCategory');
    },
    deleteResourceCategory(variables?: DeleteResourceCategoryMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteResourceCategoryMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteResourceCategoryMutation>(DeleteResourceCategoryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteResourceCategory');
    },
    createResource(variables: CreateResourceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateResourceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateResourceMutation>(CreateResourceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createResource');
    },
    updateResource(variables: UpdateResourceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateResourceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateResourceMutation>(UpdateResourceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateResource');
    },
    deleteResource(variables?: DeleteResourceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteResourceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteResourceMutation>(DeleteResourceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteResource');
    },
    getSettings(variables?: GetSettingsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetSettingsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetSettingsQuery>(GetSettingsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getSettings');
    },
    importUsers(variables?: ImportUsersMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ImportUsersMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ImportUsersMutation>(ImportUsersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'importUsers');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;